import React, { Suspense } from "react";
import { Navigate } from "react-router-dom";
import App from "../App";
const DashboardLayout = React.lazy(() => import("../component/MainScreen"));
const ApartmentDetail = React.lazy(() => import("../screens/ApartmentDetail"));
const ApartmentsList = React.lazy(() => import("../screens/ApartmentsList"));
const Home = React.lazy(() => import("../screens/Home"));

const HLayout = () => {
  return (
    <Suspense fallback="...loading">
      <Home />
    </Suspense>
  );
};

const DLayout = () => {
  return (
    <Suspense fallback="...loading">
      <DashboardLayout />
    </Suspense>
  );
};
const ApartmentD = () => {
  return (
    <Suspense fallback="...loading">
      <ApartmentDetail />
    </Suspense>
  );
};

const ApartmentL = () => {
  return (
    <Suspense fallback="...loading">
      <ApartmentsList />
    </Suspense>
  );
};

const routes = [
  {
    path: "/",
    element: <DLayout />,
    children: [
      { path: "home", element: <HLayout /> },
      { path: "apartments", element: <ApartmentL /> },
      { path: "detail", element: <ApartmentD /> },
      { path: "404", element: <div>Home</div> },
      { path: "/", element: <Navigate to="/home" /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];

export default routes;
