import thunk from "redux-thunk";
// import rootReducer from './reducers/index'
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import appReducer from "./reducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
export const rootReducer = combineReducers({
  appReducer: appReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["appReducer"],
  timeout: null,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk, logger],
});

export const persistor = persistStore(store);
