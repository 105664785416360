import logo from "./logo.svg";
import "./App.css";
import ResponsiveAppBar from "./component/AppBar";
import { Box } from "@mui/material";
import { useRoutes } from "react-router";
import router from "./router/routes";

function App() {
  const routes = useRoutes(router);
  return <>{routes}</>;
}

export default App;
